
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper,
  FormControlLabel, Checkbox, FormGroup, Button, Menu, MenuItem, Grid, Autocomplete, Popover, TextField, Typography, Alert, LinearProgress
} from '@mui/material';
import * as React from 'react';
import MonitorService from '../Services/monitor-service';
import TriggerService from '../Services/trigger-service';
import UnitService from '../Services/hospital-unit-service';
/*const rows = [
  {
    Tracking: '02-0733-110',
    Room: '402',
    Name: 'Greene, John',
    FIN: '1258937',
    Trigger: 'Short Stay',
    Completed: true,
    Other: false
  },
  {
    Tracking: '02-0733-110',
    Room: '402',
    Name: 'Greene, John',
    FIN: '1258937',
    Trigger: 'Short Stay',
    Completed: true,
    Other: false
  },
  {
    Tracking: '02-0733-110',
    Room: '402',
    Name: 'Greene, John',
    FIN: '1258937',
    Trigger: 'Short Stay',
    Completed: true,
    Other: false
  },
  {
    Tracking: '21-9342-189',
    Room: '111',
    Name: 'Jones, Brenda',
    FIN: '2537892',
    Trigger: 'OBS to IPS Long Obs',
    Completed: true,
    Other: false
  },
  {
    Tracking: '73-0972-985',
    Room: '231',
    Name: 'Ramirez, Francis',
    FIN: '7954209',
    Trigger: ' PT > 24',
    Completed: false,
    Other: false
  },
  {
    Tracking: '73-0972-985',
    Room: '217',
    Name: 'Radcliff, Mary',
    FIN: '8298729',
    Trigger: 'CC44',
    Completed: false,
    Other: false
  }
]
*/

function Contact() {
  let menu;
  const colors = {
    'Active' : 'black',
    'Snoozed': 'orange',
    'Warning': 'orange',
    'Alerted': 'red'
  }
  const [otherAnchorEl, setOtherAnchorEl] = React.useState(null);
  //const [other2AnchorEl, setOther2AnchorEl] = React.useState(null);
  const [completeAnchorEl, setComleteAnchorEl] = React.useState(null);
  const [monitors, setMonitors] = React.useState([]);
  //const [caseManagers, setCaseManagers] = React.useState([]);
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const [triggerInfo, setTriggerInfo] = React.useState(null);
  const [triggerAction, setTriggerAction] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [units, setUnits] = React.useState([]);
  const [selectedUnit, setSelectedUnit] = React.useState(null);
  const [monitorsLoaded, setMonitorsLoaded] = React.useState(false);
  const [unitsLoaded, setUnitsLoaded] = React.useState(false);
  const otherOpen = Boolean(otherAnchorEl);
  //const other2Open = Boolean(other2AnchorEl);
  const completeOpen = Boolean(completeAnchorEl);
  const infoOpen = Boolean(infoAnchorEl);
  React.useEffect(() => {
    if (!monitors.length && !monitorsLoaded) {
      const monitorService = new MonitorService();
      monitorService.getMonitors(selectedUnit).then((ms) => {
        ms.json().then((d) => {
          setMonitors(d);
          setMonitorsLoaded(true);
        });
      });
    }
  });

  const hospitalUnitChange = (e, v) => {
    var unit = v.id;
    setSelectedUnit(unit);
    setMonitorsLoaded(false);
    const monitorService = new MonitorService();
    monitorService.getMonitors(unit).then((ms) => {
      ms.json().then((d) => {
        setMonitorsLoaded(true);
        setMonitors([]);
        setMonitors(d);
      });
    });
  }

  const buildStatusMenuItems = () => {
    return (
      <Menu
                    id="complete-menu"
                    anchorEl={completeAnchorEl}
                    open={completeOpen}
                    onClose={handleCompleteClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleCompleteClose}>No change</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Change to IPS</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Change to OBS</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Change to OUT</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Other</MenuItem>
                  </Menu>
    )
  }

  const buildResourceMenuItems = () => {
    return (
      <Menu
                    id="complete-menu"
                    anchorEl={completeAnchorEl}
                    open={completeOpen}
                    onClose={handleCompleteClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleCompleteClose}>No change needed</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>No change - physician orders</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Converted to oral</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Substitution was made</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Discontinued</MenuItem>
                    <MenuItem onClick={handleCompleteClose}>Other</MenuItem>
                  </Menu>
    )
  }

  const handleCompleteClose = () => {
    setComleteAnchorEl(null);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const loadUnits = () => {
    if(units.length === 0){
    const unitService = new UnitService();
    unitService.getUnits().then((ms) => {
      ms.json().then((d) => {
        var localUnits = [];
        for(var i in d){
          var unit = d[i];
          localUnits.push({
            label: unit.name,
            id: unit.id
          })
        }
        setUnits(localUnits);
        setUnitsLoaded(true);
      });
    })
  }
  }

  loadUnits();

  const handleInfoClick = (trigger, event) => {
    const triggerService = new TriggerService();
    triggerService.getTrigger(trigger.id).then((ms) => {
      ms.json().then((d) => {
        setTriggerAction(d.action);
        setTriggerInfo(d.description);
      });
    });
    setInfoAnchorEl(event.currentTarget);
  };
  const handleCompleteClick = (event,row) => {
    setSelectedRow(row);
    setComleteAnchorEl(event.currentTarget);
  };
  const handleOtherClose = () => {
    if (selectedRow) {
      const monitorService = new MonitorService();
      monitorService.triggerAlert(selectedRow.id).then((ms) => {
        ms.json().then((d) => {
          Alert("Boom");
        });
      });
    }
    setOtherAnchorEl(null);
  };
 /* const handleOther2Close = () => {
    setOther2AnchorEl(null);
  };*/

  const handleOtherClick = (event, row) => {
    setSelectedRow(row);
    setOtherAnchorEl(event.currentTarget);
  };
  /*const handleOther2Click = (event) => {
    setOther2AnchorEl(event.currentTarget);
  };*/

  const managers = [
    { label: 'John Rogers' },
    { label: 'Mary Campbell' },
    { label: 'Bob Jones' },
    { label: 'Tom Wright' }
  ]

  const getIconColorSyle = (status) =>{
    return {color : colors[status]};
  }

  const buildUnitLoading = () => {
    return <LinearProgress ></LinearProgress>
  }

  
  const buildMonitorsLoading = () => {
    return <LinearProgress ></LinearProgress>
  }

  const buildMenu = () => {

    selectedRow && selectedRow.trigger === 'Pharmacy' ? menu = buildResourceMenuItems() : menu = buildStatusMenuItems();

  }
  buildMenu();
  return (
    <div>
      {menu}
      <h2>FEED</h2>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid item>
          <Paper>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={managers}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Assigned Manager" />}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={units}
              sx={{ width: 300 }}
              onChange={hospitalUnitChange}
              renderInput={(params) => <TextField {...params} label="Hospital Unit" />}
            />

{unitsLoaded ? null : buildUnitLoading()}
          </Paper>
        </Grid>

      </Grid>
      <br />
      <TableContainer component={Paper}>
        
{monitorsLoaded ? null : buildMonitorsLoading()}
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="right">FIN</TableCell>
              <TableCell align="right">Trigger</TableCell>
              <TableCell align="right">Room</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monitors.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                
                <TableCell align="right">{row.fin}</TableCell>
                <TableCell align="right">
                  <Button style={getIconColorSyle(row.status)} aria-describedby={infoOpen ? 'simple-popover' : undefined} onClick={event => handleInfoClick(row, event)} variant="outlined" size="medium" endIcon={<InfoRoundedIcon />}>
                    {row.trigger}
                  </Button>
                  <Popover
                    id={infoOpen ? 'simple-popover' : undefined}
                    open={infoOpen}
                    anchorEl={infoAnchorEl}
                    onClose={handleInfoClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      <strong>Description: </strong> {triggerInfo}
                    </Typography>
                    <Typography sx={{ p: 2 }}>
                      <strong>Action: </strong> {triggerAction}
                    </Typography>
                  </Popover>
                </TableCell>
                <TableCell align="right">{row.room}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                
                <TableCell align="right">
                  <Button
                    id="complete-button"
                    aria-controls={completeOpen ? 'complete-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={completeOpen ? 'true' : undefined}
                    onClick={event => handleCompleteClick(event,row)}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={row.status === 'Completed'} />} label="Resolved" />
                    </FormGroup>
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button
                    id="basic-button-1"
                    aria-controls={otherOpen ? 'basic-menu-1' : undefined}
                    aria-haspopup="true"
                    aria-expanded={otherOpen ? 'true' : undefined}
                    onClick={event => handleOtherClick(event, row)}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={row.Other} />} label="Other" />
                    </FormGroup>
                  </Button>
                  <Menu
                    id="basic-menu-1"
                    anchorEl={otherAnchorEl}
                    open={otherOpen}
                    onClose={handleOtherClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button-1',
                    }}
                  >
                    <MenuItem onClick={handleOtherClose}>Snooze</MenuItem>
                    <MenuItem onClick={handleOtherClose}>Escalate</MenuItem>
                    <MenuItem onClick={handleOtherClose}>Reassign</MenuItem>
                    <MenuItem onClick={() => handleOtherClose(row)}>Alert</MenuItem>
                    <MenuItem onClick={handleOtherClose}>Cancel</MenuItem>
                    <MenuItem onClick={handleOtherClose}>Other</MenuItem>
                  </Menu></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {buildMenu()}
       
    </div>
  );
}

export default Contact