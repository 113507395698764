
import TriggerFeedList from "../TriggerFeedList"

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  
  color: theme.palette.text.secondary,
}));

function TriggerPage() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          
        </Grid>
        <Grid item xs={8}>
          <Item>
            <h1>Available Triggers</h1>
            <TriggerFeedList />
          </Item>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
    </div>
  );
}

export default TriggerPage