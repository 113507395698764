import './App.css';
import Home from "./Pages/home";
import Contact from "./Pages/contact";
import TriggerPage from "./Pages/trigger-page"
import UnitPage from "./Pages/units"
import UnitDetailsPage from "./Pages/unit-details-page"
import Auth from "./Pages/auth"
import Demo from "./Demo"
import Dashboard from './Pages/dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScannerPage from './Pages/patient-scanner-page';

const pageWrapper = {
  "padding-top":"80px",
  "padding-left": "80px",
  "background-color": '#f2f3f5',
  'height' : '1200px'
}


const pageWrapperClear = {
  "padding-top":"80px",
  "padding-left": "0px",
}

function App(props) {
  const queryParameters = new URLSearchParams(window.location.search)
  const emr = queryParameters.get("emr")
  return (
    
    <BrowserRouter>

     {emr ? '' :  <Demo client={props.client} />}
      <div style={emr ? pageWrapperClear : pageWrapper}>
      <Routes>
        <Route path="/feed" element={<Contact />} />
        <Route path="/home" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/oldfeed" element={<Home client={props.client} />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/triggers" element={<TriggerPage />} />
        <Route path="/units" element={<UnitPage />} />
        <Route path="/unit-details" element={<UnitDetailsPage />} />
        <Route path="/patient-scanner" element={<ScannerPage />} />
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
